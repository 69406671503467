@font-face {
  font-family: "garamond";
  src: url("./assets/fonts/GARA.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "garamond", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pdf-viewer-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}
